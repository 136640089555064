import React, { useEffect, useRef, useState } from 'react'
import { signInO365 } from '../../utilities/office-apis-helpers'
import Progress from './Progress'
import { ThemeProvider } from '@mui/material'
import { ThemeService } from '../services/ThemeService'
import { RDGrid } from './Shared/Wrappers/RDGrid'
import AddInBody from './AddInBody'
import OfficeAddinMessageBar from './OfficeAddinMessageBar'
import { CircularProgress } from '@mui/material'
import { TopMenuBar } from './TopMenuBar'
import { RDButton } from './RDButton'
import { getAttachmentDetails } from './TopPane/TopPaneHelpers'
import { AppProps, AppState, clauseTypes, getClauseTypes } from './AppHelpers'
import { AccountManager } from '../../login/authConfig'

export const App = (props: AppProps) => {
  const accountManager = new AccountManager()
  const { title, isOfficeInitialized } = props
  const [state, setState] = useState<AppState>(getInitialState())
  const [clauseList, setClauseList] = useState<clauseTypes[]>([])
  const interval = useRef(null)

  const login2 = async () => {
    // check either user already has toke by word login
    try {
      await signInO365(setState)
    } catch (error) {
      console.log('Error obtaining token', error)
    }
  }

  const login = async () => {
    // check either user already has toke by word login
    try {
      Office.onReady(async () => {
        await accountManager.initialize()
        try {
          console.log('Hello')
          // handleRedirectPromise should be invoked on every page load.
          const accessToken = await accountManager.ssoGetToken(['9d8d03e6-0da2-4a20-aa7a-9259626f23db/.default'])
          const userAccount = await accountManager.ssoGetUserIdentity(['9d8d03e6-0da2-4a20-aa7a-9259626f23db/.default'])
          const idTokenClaims = userAccount.idTokenClaims as { name?: string; preferred_username?: string }
          console.log(accessToken.length)
          if (accessToken.length > 0) {
            setState((prev) => ({
              ...prev,
              authToken: accessToken,
              userName: idTokenClaims.name,
              authStatus: 'loggedIn',
              headerMessage: 'Get Data',
            }))
          } else {
            await signInO365(setState)
          }
        } catch (error) {
          const errorData = {
            errorMessage: error.errorCode,
            message: error.errorMessage,
            errorCode: error.stack,
          }
          Office.context.ui.messageParent(JSON.stringify({ status: 'failure', result: errorData }))
        }
      })
    } catch (error) {
      console.log('Error obtaining token', error)
    }
  }

  useEffect(() => {
    if (state.authStatus === 'notLoggedIn') {
      login()
    } else if (state.authStatus === 'loggedIn') {
      getAttachmentDetails(setState, state.authToken)
    }
  }, [state?.authStatus])

  useEffect(() => {
    if (isOfficeInitialized) {
      interval.current = setInterval(checkSaveEnabled, 3000)
    }
    return () => {
      clearInterval(interval.current)
    }
  }, [isOfficeInitialized, state?.isSaveDisabled])

  useEffect(() => {
    if (state.attachmentEditSession?.instance_id) {
      getClauseTypes(state.authToken, state.attachmentEditSession.instance_id, setClauseList)
    }
  }, [state?.attachmentEditSession?.instance_id])

  // useEffect(() => {
  //   if (clauseList?.length > 0) {
  //     getClauseTypeHeaderMapping(clauseList, state.authToken)
  //   }
  // }, [clauseList])

  const checkSaveEnabled = async () => {
    try {
      await Word.run(async (context) => {
        const trackedChanges = context.document.body.getTrackedChanges()
        trackedChanges.load('date')
        await context.sync()
        const filteredItems = trackedChanges.items?.filter((item) => item.date >= state?.lastSavedAt)
        if (filteredItems.length > 0) {
          setState((prev) => ({ ...prev, isSaveDisabled: false }))
        } else {
          setState((prev) => ({ ...prev, isSaveDisabled: true }))
        }
      })
    } catch (error) {
      console.log('Scroll error', error)
    }
  }

  if (!isOfficeInitialized) {
    return <Progress title={title} logo='assets/logo.png' message='Please sideload your add-in to see app body.' />
  }
  return (
    <>
      <ThemeProvider theme={ThemeService.getDefaultTheme()}>
        {state?.errorMessage ? <OfficeAddinMessageBar onDismiss={() => {}} message={state?.errorMessage} /> : null}
        <RDGrid
          container
          style={{ paddingTop: 0 }}
          direction='column'
          sx={{
            bgcolor: '#FFFFFF',
            paddingTop: 0,
          }}>
          <TopMenuBar appSetState={setState} appState={state} />
          {state?.authStatus === 'notLoggedIn' && (
            <RDGrid>
              <RDButton onClick={login2}>{'Login'}</RDButton>
            </RDGrid>
          )}
          {state?.authStatus === 'loginInProcess' && (
            <RDGrid container sx={{ marginTop: '24px' }}>
              <RDGrid container justifyContent={'center'}>
                <RDGrid>
                  <CircularProgress />
                </RDGrid>
              </RDGrid>
              <RDGrid container justifyContent={'center'}>
                <RDGrid>
                  <RDGrid>{`Sign in to continue`}</RDGrid>
                </RDGrid>
              </RDGrid>
            </RDGrid>
          )}
          {state?.authStatus === 'loggedIn' && (
            <AddInBody state={state} setState={setState} clauseList={clauseList}></AddInBody>
          )}
        </RDGrid>
      </ThemeProvider>
    </>
  )
}
const getInitialState = () => {
  return {
    authStatus: 'notLoggedIn',
    fileFetch: 'notFetched',
    headerMessage: 'Welcome',
    errorMessage: null,
    authToken: null,
    userName: null,
    filter: undefined,
    isSaveDisabled: true,
    attachmentEditSession: undefined,
    lastSavedAt: new Date(),
  }
}
