import { getGraphData } from '../../utilities/microsoft-graph-helpers'
import { AttachmentEditSesssion } from './TopPane/TopPaneHelpers'

export interface AppState {
  authStatus: string
  authToken: string
  userName: string
  isSaveDisabled: boolean
  attachmentEditSession: AttachmentEditSesssion
  lastSavedAt: Date
  filter?: string
  fileFetch?: string
  headerMessage?: string
  errorMessage?: string
}
export interface AppProps {
  title: string
  isOfficeInitialized: boolean
}

export interface clauseTypes {
  name: string
  description: string
}

export interface ContractClauseInterface {
  id: number
  requirement: string
  description: string
  is_default: boolean
  contract_clause_type_id: number
}

export interface ClauseListInterface {
  id: number
  instance_id: number
  name: string
  ai_prompt: string
  ai_prompt_enabled_default: boolean
  section: number
  contract_clauses: ContractClauseInterface[]
}

export const getClauseTypes = async (
  token: string,
  instanceId: number,
  setClauseList: React.Dispatch<React.SetStateAction<clauseTypes[]>>
) => {
  try {
    let response = await getGraphData(
      // Call backend api to fetch clauses
      `https://${process.env.REACT_APP_RAINAI}/getcontractclausetypes?instanceid=${instanceId}`,
      token
    )
    if (response.data.data?.contract_clause_type?.length > 0) {
      const clauseList: clauseTypes[] = []
      response.data.data?.contract_clause_type?.forEach((clause) => {
        if (clause.ai_prompt?.lenght > 0) {
          clauseList.push({ name: clause.name, description: clause.ai_prompt })
        }
        if (clause.contract_clauses?.length > 0) {
          clause.contract_clauses.forEach((cc) => {
            clauseList.push({ name: cc.description, description: cc.requirement })
          })
        }
      })
      setClauseList(clauseList)
    }
  } catch (requestError) {
    // This error must be from the Axios request in getGraphData,
    // not the Office.js in writeFileNamesToWorksheet.
    console.log('error', requestError)
  }
}
